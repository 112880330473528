import * as React from 'react'
import { useEffect, useState } from "react";
import { RichText } from 'prismic-reactjs'
import { navigate } from "gatsby";
import { graphql } from "gatsby";

import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import createLink from "../components/functions/createLink.js";
import useWindowWidth from "../components/functions/useWindowWidth.js";
import {
  isEmail,
  createNewHomeArray,
  getNewsletterFields,
  sliderOpenFocus,
  projectsOpenFocus,
  handleSliderHorizontalScroll,
  handleSliderAndMouseAnimation,
  handleSliderEngagementAnimation,
  handleOnSubmit,
  handleOnChange,
  handleNewsletterOnSubmit,
  debugBtn,
  openFocus,
  $_GET,
} from '../utils/mod.js';

import {
  AcquisitionNodeType,
  DataStringToObjectType
} from '../types/types.js';

import "../styles/css/home.css"

/**
 * @param {{
 * doc: unknown;
 * lang: string;
 * docs: AcquisitionNodeType[];
 * strategy: AcquisitionNodeType[];
 * typology: AcquisitionNodeType[];
 * region: AcquisitionNodeType[];
 * country: AcquisitionNodeType[];
 * setFocus: (...args: unknown) => unknown;
 * }} param 
 */
const Home = ({
  doc,
  lang,
  docs,
  strategy,
  typology,
  region,
  country,
  setFocus
}) => {

  const projectsArray = createNewHomeArray(docs, RichText);
  let windowScrollY = 0, arrayWindow = useWindowWidth();
  const newsletterFields = getNewsletterFields(doc);

  const getUrl = typeof window !== 'undefined' ? window.location.href : '';

  const createUrl = (link) => createLink(link, lang);
  const linkHandler = (e, url, section, param) => {
    e.preventDefault();
    param
      ? navigate(`${createUrl(url)}#s${section}_${param}`)
      : navigate(`${createUrl(url)}#s${section}`);
    document.querySelector('header').classList.remove('style-open');
    document.querySelector('.container-podcast').classList.remove('style-show');
  };

  const linkWithHash = (e, url, section) => linkHandler(e, url, section);
  const linkHashParam = (e, url, section, param) => linkHandler(e, url, section, param);

  let mount = true;
  useEffect(() => {
    const newsletterContainer = document.querySelector('.section-newsletter .wrapper form .container-form');
    const newsletterBtn = newsletterContainer.querySelector('.container button');

    debugBtn('.section-num');
    handleNewsletterOnSubmit(newsletterContainer, newsletterBtn);
    handleSliderAndMouseAnimation()
    handleSliderEngagementAnimation();
    handleSliderHorizontalScroll(
      window,
      document.querySelector('.section-projects .container'),
      windowScrollY,
      arrayWindow
    );

    sliderOpenFocus(docs, setFocus);
    projectsOpenFocus(docs, setFocus);

    if ($_GET('focus', getUrl) !== null) {
      const uid = $_GET('focus', getUrl);
      setTimeout(() => {
        openFocus(uid, docs, setFocus);
      }, 100);
    }

    window.addEventListener('scroll', () => windowScrollY = window.scrollY);

    return () => { window.removeEventListener('scroll', () => windowScrollY = window.scrollY) };
  }, [mount]);

  return (
    <>
      <div className="container-mouse">
        <div className="container">
          <div className="circle"></div>
        </div>
      </div>
      <div className="mouse-pointer">
        <div className="container">
          <svg className="link-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
          </svg>
        </div>
      </div>

      <section className="section-hero">
        <div className="background">

          <div className="img">
            <img src={doc.hero_img.url} alt={doc.hero_img.alt} />
          </div>
        </div>
        <div className="wrapper">
          <img className="obj-1" src="/img/home/section-hero/obj-1.svg" alt="" />
          <div className="content">
            <div className="container-text">
              <RichText render={doc.hero_title} />
              <div className="container">
                <p>{RichText.asText(doc.hero_text)}</p>
                <div className="container-btn">
                  <a className="btn" href={createUrl(doc.hero_btnlnk)} target={doc.hero_btnnt ? "_blank" : "_self"} rel="noreferrer">
                    <span className='btn-text'>{RichText.asText(doc.hero_btntxt)}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-num">
        <div className="wrapper">
          <div className="container">
            <div className="container-title">
              <RichText render={doc.number_title} />
              <p>{RichText.asText(doc.number_text)}</p>
            </div>
            <div className="container-text">
              <div className="container-case">
                {doc.number_grp.map((item, i) => (
                  <div key={i} className="case">
                    <h3>{RichText.asText(item.title)}</h3>
                    <p>{RichText.asText(item.text)}</p>
                  </div>
                ))}
              </div>
              <div className="text">
                <div className="head">
                  <div className="source">
                    {RichText.asText(doc.number_source)}
                  </div>
                  <div className="container-infos">
                    <div className="info">{RichText.asText(doc.number_update)}</div>
                    <div className="info">{RichText.asText(doc.number_data)}</div>
                  </div>
                </div>
                <p>{RichText.asText(doc.number_text2)}</p>
              </div>
              <div className="container-btn">
                <a href={createUrl(doc.number_btnlnk)} onClick={e => linkWithHash(e, doc.number_btnlnk, doc.number_btnselect)} className="btn">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <rect />
                  </svg>
                  <span className='btn-text'>{RichText.asText(doc.number_btntxt)}</span>
                </a>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="section-slider">
        <div className="container-slider">
          <div className="slider">
            <div className="container-el">
              {doc.slider_grp1.map((item, i) => (
                <React.Fragment key={i}>
                  {docs.map((art, a) => {
                    /** @type {DataStringToObjectType} */
                    let t = JSON.parse(art.node.dataString);
                    if (item.link.uid === art.node.uid) {
                      return (
                        <div key={a} className="el" data-href={art.node.uid}>
                          <img src={t.bthumbnail_img.url} alt={t.bthumbnail_img.alt} />
                          <div className="text">
                            <div className="title">{RichText.asText(t.content_title)}</div>
                            <p>{RichText.asText(t.bthumbnail_desc)}</p>
                          </div>
                        </div>
                      )
                    } else { return (<React.Fragment key={a} />) }
                  })}
                </React.Fragment>
              ))}
            </div>
            <div className="container-shape">
              <div className="shape"></div>
              <div className="shape"></div>
            </div>
            <div className="container-area">
              <div className="area action-prev"></div>
              <div className="area"></div>
              <div className="area action-next"></div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div className="container-infos">
              {doc.slider_grp1.map((item, i) => (
                <div key={i} className="li-infos">
                  {docs.map((art, a) => {
                    let st = "";
                    let ty = "";
                    let typos = [];
                    let re = "";
                    let regs = [];
                    let co = "";
                    /** @type {DataStringToObjectType} */
                    let t = JSON.parse(art.node.dataString);
                    if (item.link.uid === art.node.uid) {
                      strategy.map((strat, s) => {
                        if (strat.node.slugs[0] === t.global_strategy.slug) {
                          st = JSON.parse(strat.node.dataString).name;

                        }

                      });
                      typology.map((typo, y) => {
                        for (let i = 0; i < t.global_investments.length; i++) {
                          if (typo.node.slugs[0] === t.global_investments[i].typo.slug) {
                            ty = JSON.parse(typo.node.dataString).name;
                            typos.push(ty[0].text + " ");
                          }
                        }
                      });
                      region.map((reg, r) => {
                        for (let i = 0; i < t.global_regions.length; i++) {
                          if (reg.node.slugs[0] === t.global_regions[i].region.slug) {
                            re = JSON.parse(reg.node.dataString).name;
                            regs.push(re[0].text + " ");
                          }
                        }
                        if (reg.node.slugs[0] === t.global_regions[0].region.slug) {
                          country.forEach(function (cou, c) {
                            if (cou.node.slugs[0] === JSON.parse(reg.node.dataString).country.slug) {
                              co = JSON.parse(cou.node.dataString).name;
                            }
                          })
                        }
                      });
                      return (
                        <React.Fragment key={a}>
                          <div className="info">{RichText.asText(st)}</div>
                          <div className="info">{typos}</div>
                          <div className="info">{regs} - {RichText.asText(co)}</div>
                        </React.Fragment>
                      )
                    }

                  })}
                </div>
              ))}
            </div>
            <div className="container-action">
              <div className="play style-play" data-interval="4000">
                <img src="/img/home/section-slider/icn-play.svg" alt="" />
                <img src="/img/home/section-slider/icn-pause.svg" alt="" />
              </div>
              <div className="container-tab">
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-projects">
        <div className="container">
          <div className="container-sticky">
            <div className="wrapper">
              <div className="container-title">
                <div className="head">
                  <h2>{RichText.asText(doc.projects_title)}</h2>
                  <a href={createUrl(doc.projects_btnlnk)} onClick={e => linkWithHash(e, doc.projects_btnlnk, doc.projects_btnselect)} className="btn">
                    <span className='btn-text'>{RichText.asText(doc.projects_btntxt)}</span>
                  </a>
                </div>
                <p>{RichText.asText(doc.projects_text)}</p>
              </div>

              <div className="container-el">
                {projectsArray.map((art, a) => {
                  if (a < 7 && JSON.parse(art[3].node.dataString).global_linked === true) {
                    let t = JSON.parse(art[3].node.dataString);
                    return (
                      <div key={a} className="el" data-href={art[3].node.uid}>
                        <div className="cover">
                          <img src={t.sthumbnail_img.url} alt={t.sthumbnail_img.alt} />
                          <div className="container-text">
                            <p>{RichText.asText(t.content_text)}</p>
                            <div className="link">
                              <svg className="link-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                              </svg>
                              <span className="link-text">{RichText.asText(t.sthumbnail_btntxt)}</span>
                            </div>
                          </div>
                        </div>
                        <div className="text">
                          <div className="name">
                            <RichText render={t.sthumbnail_adress} />
                          </div>
                          <div className="container-tag">
                            {strategy.map((strat, s) => {
                              let st = "";
                              if (strat.node.slugs[0] === t.global_strategy.slug) {
                                st = JSON.parse(strat.node.dataString).name;
                                return (
                                  <div key={s} className="tag">{RichText.asText(st)}</div>
                                )
                              } else { return (<React.Fragment key={s} />) }
                            })}
                            {typology.map((typo, y) => {
                              let ty = "";
                              for (let i = 0; i < t.global_investments.length; i++) {
                                if (typo.node.slugs[0] === t.global_investments[i].typo.slug) {
                                  ty = JSON.parse(typo.node.dataString).name;
                                  return (
                                    <div key={y} className="tag">{RichText.asText(ty)}</div>
                                  )
                                }
                              }
                            })}
                            {region.map((reg, r) => {
                              let re = "";
                              for (let i = 0; i < t.global_regions.length; i++) {
                                if (reg.node.slugs[0] === t.global_regions[i].region.slug) {
                                  re = JSON.parse(reg.node.dataString).name;
                                  return (
                                    <div key={r} className="tag">{RichText.asText(re)}</div>
                                  )
                                }
                              }
                            })}
                            {region.map((reg, r) => {
                              let co = "";
                              if (reg.node.slugs[0] === t.global_regions[0].region.slug) {
                                country.forEach(function (cou, c) {
                                  if (cou.node.slugs[0] === JSON.parse(reg.node.dataString).country.slug) {
                                    co = JSON.parse(cou.node.dataString).name;
                                  }
                                })
                                return (
                                  <div key={r} className="tag">{RichText.asText(co)}</div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="section-engagement">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.esg_title)}</h2>
            <p>{RichText.asText(doc.esg_text)}</p>
          </div>
          <div className="container">
            <div className="container-tab">
              {doc.esg_grp.map((item, i) => (
                <a href={createUrl(item.link)} onClick={e => linkHashParam(e, item.link, item.select, i)} key={i} className="tab">
                  <h3>{RichText.asText(item.title)}</h3>
                  <img src="/img/home/section-esg/arrow.svg" alt="arrow" />
                </a>
              ))}
            </div>
            <div className="container-el">
              {doc.esg_grp.map((item, i) => (
                <div key={i} className="el">
                  <p>{RichText.asText(item.text)}</p>
                </div>
              ))}
            </div>
            <div className="container-img">
              <div className="shape">
                <svg height="0" width="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
                      <path transform="scale(0.001214) rotate(-80) translate(-680, 120)" d="M709 709C521.039 708.792 340.835 634.03 207.918 501.114C75.0025 368.198 0.228624 187.982 0 0L347.315 0C347.44 95.8972 385.586 187.831 453.388 255.641C521.19 323.45 613.113 361.6 709 361.725L709 709Z" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="list-img">
                {doc.esg_grp.map((item, i) => (
                  <img key={i} src={item.img.url} alt={item.img.alt} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-newsletter">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.newsletter_title)}</h2>
            <p>{RichText.asText(doc.newsletter_text)}</p>
          </div>
          <form onSubmit={(e) => handleOnSubmit(e, getUrl)}>
            <div className="container-form">
              <div className="container">
                <div className="container-input">
                  {newsletterFields.map((field) => (
                    <div className="input" key={field.id}>
                      <input
                        type={field.id === "nEmail" ? "email" : "text"}
                        id={field.id}
                        onChange={handleOnChange}
                      />
                      <span className="placeholder">
                        {RichText.asText(field.content)} <i>*</i>
                      </span>
                      <span className="error">
                        Champ invalide
                      </span>
                    </div>
                  ))
                  }
                  <div className="input">
                    <input
                      name="cc-num"
                      className="hidden"
                      required=""
                      value="HubspotCollectedFormsWorkaround"
                      id="cc-num"
                      onChange={handleOnChange}
                    />
                  </div>
                </div>
                <button>
                  <span className='btn-text'>{RichText.asText(doc.newsletter_btntxt)}</span>
                  <div className="loading">
                    <svg className="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-load.svg#content"></use>
                    </svg>
                    <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-Check.svg#content"></use>
                    </svg>
                  </div>
                </button>
              </div>
              <p className="rqd">
                <i>*</i> {RichText.asText(doc.newsletter_rqd)}
              </p>
              <div className="container-checkbox">
                <div className="checkbox">
                  <input type="checkbox" id="check1" onChange={handleOnChange} />
                  <div className="text" id="check1Txt">
                    <p>{RichText.asText(doc.newsletter_info1)}</p>
                    <a href={createUrl(doc.newsletter_lnklnk)} target={doc.newsletter_lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(doc.newsletter_lnktxt)}</a>
                  </div>
                </div>
                <div className="checkbox">
                  <input type="checkbox" id="check2" />
                  <div className="text">
                    <p>{RichText.asText(doc.newsletter_info2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

const Init = ({ data }) => {
  const lang = data.prismicHome.lang.substring(0, 2);
  const header = JSON.parse(data.prismicHeader.dataString);
  const categories = data.allPrismicCategories.edges;
  const footer = JSON.parse(data.prismicFooter.dataString);
  const doc = JSON.parse(data.prismicHome.dataString);
  const docs = data.allPrismicFocus.edges;
  const articles = data.allPrismicArticle.edges;
  const strategy = data.allPrismicStrategy.edges;
  const typology = data.allPrismicInvestmentTypology.edges;
  const region = data.allPrismicRegions.edges;
  const country = data.allPrismicCountries.edges;

  const [focus, setFocus] = useState(null);

  if (!doc || !header || !footer) return null;

  return (
    <>
      <Head doc={doc} lang={lang} />
      <div className="home">
        <Layout
          focus={focus}
          docs={docs}
          setFocus={setFocus}
          header={header}
          articles={articles}
          cat={categories}
          footer={footer}
          lang={lang}
          type={"home"}
        >
          <main>
            <Home
              focus={focus}
              setFocus={setFocus}
              doc={doc}
              lang={lang}
              docs={docs}
              strategy={strategy}
              typology={typology}
              region={region}
              country={country}
            />
          </main>
        </Layout>
      </div>
    </>
  );
};
export default Init;

export const query = graphql`
query callHome($lang: String) {
  prismicHeader(lang: { eq : $lang }) {
    dataString
  }
  allPrismicCategories(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicStrategy(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicInvestmentTypology(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicRegions(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicCountries(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicFocus(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            prismicId
            dataString
            first_publication_date
            uid
        }
    }
  }
  allPrismicArticle(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            dataString
            first_publication_date
        }
    }
  }
  prismicHome(lang: {eq: $lang}) {
      dataString
      lang
      uid
  }
  prismicFooter(lang: {eq: $lang}) {
    dataString
  }
}
`